<template>
    <div class="create-order">
        <div style="width: 1500px; margin: 108px auto 0; background-color: #FFFFFF; min-height: calc(100vh - 108px)">
            <div style="padding: 10px 20px;" v-if="isSuccess === 0">
                <div>
                    <div style="font-size: 16px; font-weight: 600; margin-bottom: 10px">选择收货地址</div>
                    <div>
                        <div style="display: flex; flex-wrap: wrap">
                            <div v-for="(item, index) in addressData" :key="index" @click="toSelectAddress(item)" class="card hand op" :class="{'select-border': type === item.id}" style="margin-right: 30px; margin-bottom: 10px">
                                <div class="tag-address" v-if="item.isDefault === 1">默认地址</div>
                                <div>
                                    <div class="card-title" v-if="item.deliveryScope.cityName">{{item.deliveryScope.cityName[0]}}{{item.deliveryScope.cityName[1]}}（{{item.name}}）</div>
                                    <el-divider></el-divider>
                                    <div class="card-content">{{item.deliveryScope.address}}</div>
                                </div>
                                <div class="triangle" v-if="type === item.id">
                                    <i class="el-icon-check tr-icon"></i>
                                </div>
                            </div>
                        </div>
                        <div @click="b('/user/money/')" class="hand op" style="display: flex; justify-content: end; color: #2970FF; margin-right: 20px">管理收货地址</div>
                    </div>
                </div>
                <div style="margin-top: 20px">
                    <div style="font-size: 16px; font-weight: 600; margin-bottom: 10px">确认订单信息</div>
                    <div>
                        <div class="title-card">
                            <div class="title-content" style="width: 40%">
                                <span>商品信息</span>
                            </div>

                            <div class="title-content" style="width: 15%">
                                <span>单价（元）</span>
                            </div>
                            <div class="title-content" style="width: 15%">
                                <span>数量</span>
                            </div>
                            <div class="title-content" style="width: 30%">
                                <span>合计（元）</span>
                            </div>
                        </div>
                        <div style="margin: 20px 0 30px 0;">
                            <div style="margin-bottom: 10px;overflow: hidden">
                                <div style=" float: left; width: 24px; height: 24px; border-radius: 50%"></div>
                                <div style="float: left;margin-right: 10px; font-weight: 600">{{orderInfo.shopName}}</div>
                                <div class="tag" style="background-color: #2970FF;">企业</div>
                                <div class="tag" style="background-color: #7BD7A2;">实地认证</div>
                                <div class="tag" style="background-color: #FF9800;">10年老店</div>
                            </div>
                            <div class="content-card-select" v-if="JSON.stringify(orderInfo) !== '{}'">
                                <div style="display: flex; width: 40%">
                                    <div style="width: 19%; height: 90px; margin-right: 10px">
                                        <img style="width: 100%; height: 100%; background-color: #FFFFFF" v-if="orderInfo.skuList" :src="orderInfo.skuList.images[0]">
                                    </div>
                                    <div style="width: 69%">
                                        <div>{{orderInfo.goodsName}}</div>
                                        <div style="color: #999999; margin-top: 10px" v-if="orderInfo.skuList">
                                            <span v-for="(key, index) in orderInfo.skuList.specValues" :key="index">
                                                {{Object.keys(key)[0]}}: {{Object.values(key)[0]}}
                                            </span>
                                        </div>
                                    </div>
                                </div>

                                <div style="text-align: center; font-weight: 600; width: 15%">
                                    ￥{{orderInfo.goodsUnitPrice}}
                                </div>
                                <div style="text-align: center; font-weight: 600; width: 15%">
                                    <el-input-number size="small" @change="changeCount" step-strictly v-model="orderInfo.goodsCount" :min="1"></el-input-number>
                                    <div v-if="msg" style="margin-top: 12px; font-size: 14px; color: #FF444C">{{msg}}</div>
                                </div>
                                <div style="text-align: center; font-weight: 600; width: 30%" v-if="orderInfo.goodsCount > 0 && orderInfo.goodsUnitPrice > 0">
                                    ￥{{utils.keepTwoDecimalFull(orderInfo.goodsCount * orderInfo.goodsUnitPrice)}}
                                </div>
                            </div>
                        </div>
                        <div style="margin-top: 10px; display: flex">
                            <el-checkbox style="margin-top: 4px" @change="changeChecked" v-model="checked">开具发票</el-checkbox>
                            <div v-if="checked === true" style="margin-left: 30px">
                                <el-select size="small" v-model="invoiceId" placeholder="请选择">
                                    <el-option
                                            v-for="item in ticketData"
                                            :key="item.id"
                                            :label="item.addressInfo.title"
                                            :value="item.id">
                                    </el-option>
                                </el-select>
                                <span @click="b('/user/money/')" class="hand op" style=" color: #2970FF; margin:0 30px">管理发票信息</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div style="border-bottom: 2px solid #DCDFE6; margin: 20px 0"></div>
                <div style="display: flex; justify-content: end;" v-if="JSON.stringify(orderInfo) !== '{}'">
                    <div style="width: 600px; height: 100px; border: 2px solid #ff0036; padding: 30px 20px; overflow: hidden">
                        <div style="text-align: end">
                            <span style="font-size: 16px; font-weight: 600">实付款</span>
                            <span v-if="orderInfo.goodsCount > 0 && orderInfo.goodsUnitPrice > 0" style="font-size: 28px; font-weight: 600; color: #ff0036;">￥{{utils.keepTwoDecimalFull(orderInfo.goodsCount * orderInfo.goodsUnitPrice)}}</span>
                        </div>
                        <div style="text-align: end; margin-top: 10px">
                            <div style="font-size: 15px; font-weight: 600" v-if="selectAddress.deliveryScope">寄送至：{{selectAddress.deliveryScope.cityName[0]}} {{selectAddress.deliveryScope.cityName[1]}} {{selectAddress.deliveryScope.cityName[2]}} {{selectAddress.deliveryScope.address}}</div>
                            <div style="font-size: 15px; font-weight: 600">收货人：{{selectAddress.name}}</div>
                        </div>
                    </div>
                </div>
                <div v-if="!msg" style="display: flex; justify-content: end; margin-bottom: 40px">
                    <div class="hand op" @click="submitOrder" style="width: 180px; height: 40px; text-align: center; line-height: 40px; background-color: #ff0036; font-size: 18px; font-weight: 600; color: #FFFFFF">提交订单</div>
                </div>

            </div>
            <div style="padding: 30px 20px;" v-if="isSuccess === 1">
                <div style="text-align: center">
                    <div style="color: #4caf50; font-size: 30px; font-weight: 600">支付成功</div>
                    <div style="margin-top: 60px">
                        <span @click="goBack" class="success-btn hand op" style="margin-right: 30px; background-color: #F5F5F5">返回</span>
                        <span @click="goOrder" class="success-btn hand op" style="background-color: #F5F5F5">查看订单</span>
                    </div>

                </div>
            </div>

            <div style="padding: 30px 20px;" v-if="isSuccess === 2">
              <div style="text-align: center">
                <div style="color: #4caf50; font-size: 30px; font-weight: 600">订单已生成，请前往订单页查看</div>
                <div style="margin-top: 60px">
<!--                  <span @click="goBack" class="success-btn hand op" style="margin-right: 30px; background-color: #F5F5F5">返回</span>-->
                  <span @click="goOrder" class="success-btn hand op" style="background-color: #F5F5F5">查看订单</span>
                </div>
              </div>
            </div>
        </div>

        <!--支付弹窗-->
        <el-dialog :visible.sync="showPay" width="40%"  :show-close="false" class="event-form" :close-on-click-modal="false">
            <div class="box-head">
                请支付您的产品费用
                <img src="../imgs/cancel.png" style="width: 32px; height: 32px;position: absolute;right: 30px;top: 14px;cursor: pointer;" @click="handleClose">
            </div>
            <div style="padding: 20px 40px; overflow: hidden">
                <div style="margin-bottom: 20px;display: flex; justify-content: space-between; height: 50px; border-top: 1px solid #DEDDDD; border-bottom: 1px solid #DEDDDD;">
                    <div style="font-size: 18px; font-weight: 600; line-height: 50px" v-if="JSON.stringify(orderInfo) !== '{}'">
                        <span>支付金额：</span>
                        <span v-if="orderInfo.goodsCount > 0 && orderInfo.goodsUnitPrice > 0" style="color: #EE3F4D">￥{{utils.keepTwoDecimalFull(orderInfo.goodsCount * orderInfo.goodsUnitPrice)}}</span>
                    </div>
                </div>
                <!-- 快捷卡支付  -->
                <div>
                    <div v-if="orderInfo.goodsCount > 0 && orderInfo.goodsUnitPrice > 0" style="width: 400px;margin-left: 28%;margin-top: 10px;">
                        <!--        支付类型参考-->
                        <aa-da-pay-code type="3" :payAmt="utils.keepTwoDecimalFull(orderInfo.goodsCount * orderInfo.goodsUnitPrice)" :payType="10" OrderType="10" :orderNo="payOrderNo" :collectionUuid="orderInfo.shopUuid" @change="changeOrder($event)" ref="child"></aa-da-pay-code>
                    </div>
                </div>
            </div>
        </el-dialog>
        <pay-loading v-if="loading === true"></pay-loading>
    </div>
</template>

<script>

    import AaDaPayCode from "@/components/common/Pay/AaDaPayCode";
    export default {
        name: "createOrder",
        components: {AaDaPayCode},
        data() {
            return {
                isSuccess: 0,
                showPay: false,
                addressData: [],  // 地址列表
                selectAddress: {},
                ticketData: [],  // 发票列表
                type: 0,
                count: 1,
                invoiceId: '',
                checked: false,
                orderInfo: {},
                msg: '',

                // 支付
                payOrderNo: '',
                order: '',
                loading: false,

                // 机构
                showOrganPay: false
            }
        },
        computed: {
            user() {
                return this.$store.state.user
            }
        },
        created: function () {
        },

        mounted: function () {
            let that = this;
            //监听支付回调
            this.$EventBus.$on("payMsg", (data) => {
                that.$nextTick(() => {
                    if (data.code == 100) {
                        that.showPay = false;
                        that.isSuccess = 1;
                    }
                    that.loading = false;
                });
            });
            this.getAddress();
            this.getTicketList();
            this.getPayInfo()
            //监听支付
            this.$EventBus.$on("toPay", (data) => {
                this.$nextTick(() => {
                    if (data.code == 100) {
                        this.toPaySelect();
                    }
                });
            });
        },
        methods: {
            //弹窗关闭前事件
            handleClose() {
                this.getPayInfo();
                this.showPay = false;

            },
            // 获取结算信息
            getPayInfo() {
                this.newApi.buyNowGoodsInfo({id: this.$route.query.id}).then(res => {
                    if (JSON.stringify(res.data) != '{}'){
                        this.orderInfo = res.data
                        if (res.data.checkGoodsInfo.goodsStatus > 1) {
                            this.msg = this.res.data.checkGoodsInfo.msg
                        }
                    }else{
                        this.isSuccess = 2
                    }

                })
            },

            // 计算库存
            changeCount() {
                let param = {};
                param.type = 1;
                param.id = this.orderInfo.id;
                param.goodsId = this.orderInfo.goodsId;
                param.goodsCount = this.orderInfo.goodsCount;
                param.goodsPrice = this.utils.keepTwoDecimalFull(this.orderInfo.goodsCount * this.orderInfo.goodsUnitPrice);
                this.newApi.shopGoodsAddAndSubtract(param).then(res => {
                    if (res.isSuccess === 1) {
                        if (res.data.checkGoodsInfo.goodsStatus === 1) {
                            this.msg = ''
                        } else {
                            this.msg = res.data.checkGoodsInfo.msg
                        }
                    }
                })
            },

            // 提交订单
            submitOrder() {
              let params = {};
              params.id = this.orderInfo.id;
              params.invoiceId = this.invoiceId;
              params.addressId = this.selectAddress.id
              this.newApi.buyNowGoods(params).then(res => {
                  if (res.isSuccess === 1) {
                      this.showPay = true;
                      this.payOrderNo = res.data.orderNo;
                  } else {
                      this.utils.err(res.checkGoodsInfo.msg)
                  }
              })
            },

            // 返回上一页
            goBack() {
                this.$router.go(-1)
            },

            // 查看订单
            goOrder() {
                this.$router.push('/shop/order/')
            },

            // 取消发票
            changeChecked(param) {
                if (!param) {
                    this.invoiceId = ''
                }
            },

            // 选择收货地址
            toSelectAddress(item) {
                this.type = item.id;
                this.selectAddress = item
            },

            // 获取发票列表
            getTicketList() {
                this.newApi.getInvoiceList().then(res => {
                    this.ticketData = res.data
                })
            },

            // 获取收货地址
            getAddress() {
                this.newApi.getAddressList().then(res => {
                    this.addressData = res.data;
                    for (const item of this.addressData) {
                        if (item.isDefault === 1) {
                            this.type = item.id;
                            this.selectAddress = item
                        }
                    }
                })
            },

            //子组件传过来的订单号 此方法在父页面必写
            changeOrder(order){
                this.orderNo = order;
            },

            //父组件获取子组件的验证码 this.$refs.child.childMethods() 必写
            toPaySelect(){
                var that = this;
                if (that.user.userType === 1) {
                    var obj = this.$refs.child.childMethods();
                    if (obj.code == "") {
                        that.utils.err("请填写验证码");
                        return false;
                    }
                    if (obj.payPwd == "") {
                        that.utils.err("请填写密码");
                        return false;
                    }
                    let params = {};
                    params.orderNo = that.orderNo;
                    params.code = obj.code;
                    params.payPwd = obj.payPwd;

                    that.toPay(params);
                }
            },

            // 支付
            toPay(params) {
                var that = this;
                that.loading = true;
                that.newApi.settleAccountPennyDetermine(params).then((res) => {
                    that.loading = false;
                    if (res.isSuccess == 1){

                    }
                })
            },


            // 关闭支付
            closeSubPay() {
                this.showPay = false;
            },

            // 机构支付
            goOrganPay() {
                this.showOrganPay = true
            },
        }
    }
</script>

<style scoped>
    .create-order .card {
        width: 300px;
        height: 100px;
        padding: 10px;
        position: relative;
        background-color: #FFFFFF;
        box-shadow: 0 1px 6px 1px rgba(0,0,0,0.16);
    }

    .create-order .card .icon {
        width: 48px;
        height: 50px;
        margin-right: 20px;
    }

    .create-order .card .card-title {
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 2px;
    }

    .create-order .card .card-content {
        font-weight: 600;
        font-size: 14px;
        color: #999999;
    }

    .create-order .select-border {
        border: 2px solid #2970FF;
    }

    .create-order .tag-address{
        position:absolute;
        top: 0;
        right: 0;
        width: 60px;
        height: 20px;
        line-height: 20px;
        text-align: center;
        color: #FFFFFF;
        background-color: rgba(0,0,0,0.2);
        font-size: 12px;
    }

    .create-order .triangle{
        position:absolute;
        bottom: 0;
        right: 0;
        width: 0;
        height: 0;
        border: 16px solid #fff;
        border-bottom-color: #2970FF;
        border-right-color: #2970FF;
        border-top-color:transparent ;
        border-left-color: transparent;
    }

    .create-order .tr-icon {
        color: #FFFFFF;
        position:absolute;
        font-weight: 600;
    }


    .create-order .title-card {
        padding: 14px 20px;
        display: flex;
        border: 1px solid #DCDCDC;
    }

    .create-order .title-content {
        font-size: 16px;
        text-align: center;
    }

    .create-order .tag {
        float: left;
        margin-top: 1px;
        padding: 2px 6px;
        font-size: 12px;
        border-radius: 5px;
        color: #FFFFFF;
        margin-right: 10px;
    }

    .create-order .content-card-select {
        padding: 20px 20px;
        display: flex;
        background-color: #F8F8F8;
        border: 1px solid #DCDCDC;
    }

    .create-order .success-btn {
        width: 100px;
        height: 32px;
        display: inline-block;
        font-weight: 600;
        text-align: center;
        line-height: 32px;
        font-size: 18px;
    }

    .create-order .box-head {
        padding: 10px;
        font-size: 18px;
        font-weight: 600;
    }

    .create-order .el-divider--horizontal {
        margin: 10px 0;
    }

    /deep/ .event-form .el-dialog__header {
        padding: 0;
    }

    /deep/ .event-form .el-dialog__body {
        padding: 0;
    }

</style>
